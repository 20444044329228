
import Http from './Http'

export default {
    async login(credentials) {
        return await Http.post('auth/login', credentials)
    },
    async register(userData) {
        return await Http.post('/customers', userData)
    },
    async me() {
        return await Http.get('/user')
    }
}