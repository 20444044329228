<script>
import { required } from "vuelidate/lib/validators";
import appConfig from "@/app.config";
import { mapMutations } from "vuex";
import Auth from '@/services/Auth'
//import images from '../ui/images.vue';

/**
 * Login component
 */
export default {
  //components: { images },
  page: {
    title: "Login",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
        username: "",
        password: "",
        submitted: false,
        authError: null,
        tryingToLogIn: false,
        isAuthError: false,
        login_image_url: process.env.VUE_APP_LOGIN_IMAGE_URL,
    };
  },
  validations: {
      username: {
          required,
      },
      password: {
          required,
      },
  },
  computed: {
      notification() {
          return this.$store ? this.$store.state.notification : null;
      },
      notificationAutoCloseDuration() {
          return this.$store && this.$store.state.notification ? 5 : 0;
      },
  },
  mounted() {
      document.body.classList.add("authentication-bg");
  },
  methods: {
    ...mapMutations(["setUser", "setAccessToken","setRefreshToken"]),
    // Try to log the user in with the username
    // and password they provided.
    async tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {

            this.tryingToLogIn = true;
            this.authError = null;
            this.isAuthError = false;

            try {
                const response = await Auth.login({username: this.username, password: this.password})
                localStorage.setItem("user_id", response.data.user_id);
                localStorage.setItem("username", this.username);
                localStorage.setItem("access_token", response.data.tokens.access_token);
                localStorage.setItem("refresh_token", response.data.tokens.refresh_token);
                localStorage.setItem("expire", response.data.tokens.expire);
                this.tryingToLogIn = false;
                this.isAuthError = false;
                this.$router.push({name: "home"});
            } catch (error) {
                this.tryingToLogIn = false;
                this.authError = error.response.data.error ? error.response.data.error : "";
                this.isAuthError = true;
            }
        
      }
    },
  },
};
</script>

<template>
  <div>
    
    <div class="account-pages my-5 pt-sm-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <router-link to="/" class="mb-1 d-block auth-logo">
                <img
                  :src="login_image_url"
                  alt
                  height="150"
                  class="logo logo-dark"
                />
                <img
                  :src="login_image_url"
                  alt
                  height="150"
                  class="logo logo-light"
                />
              </router-link>
            </div>
          </div>
        </div>
        <div class="row align-items-center justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">Sign in to Admin</h5>
                </div>
                <div class="p-2 mt-4">
                  <b-alert
                    v-model="isAuthError"
                    variant="danger"
                    class="mt-3"
                    dismissible
                    >{{ authError }}</b-alert
                  >

                  <div
                    v-if="notification.message"
                    :class="'alert ' + notification.type"
                  >
                    {{ notification.message }}
                  </div>

                  <b-form @submit.prevent="tryToLogIn">
                    <b-form-group
                      id="input-group-1"
                      class="mb-3"
                      label="Username"
                      label-for="input-1"
                    >
                      <b-form-input
                        id="input-1"
                        v-model="username"
                        type="text"
                        placeholder="Enter Username"
                        :class="{ 'is-invalid': submitted && $v.$error }"
                      ></b-form-input>
                        <div v-if="submitted && $v.username.$error" class="invalid-feedback">
                            <span v-if="!$v.username.required">Username is required.</span>
                        </div>
                    </b-form-group>

                    <b-form-group id="input-group-2" class="mb-3">
                      <div class="float-end">
                        <!-- <router-link
                          to="/account/forgot-password"
                          class="text-muted"
                          >Forgot password?</router-link
                        > -->
                      </div>
                      <label for="input-2">Password</label>
                      <b-form-input
                        id="input-2"
                        v-model="password"
                        type="password"
                        placeholder="Enter password"
                        :class="{
                          'is-invalid': submitted && $v.password.$error,
                        }"
                      ></b-form-input>
                      <div
                        v-if="submitted && !$v.password.required"
                        class="invalid-feedback"
                      >
                        Password is required.
                      </div>
                    </b-form-group>
                    <!-- <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="auth-remember-check"
                      />
                      <label class="form-check-label" for="auth-remember-check"
                        >Remember me</label
                      >
                    </div> -->
                    <div class="mt-3">
                      <b-button type="submit" variant="primary" class="w-sm" :disabled="tryingToLogIn">
                          Log In
                      </b-button>
                    </div>
                   
                  </b-form>
                </div>
                <!-- end card-body -->
              </div>
              <!-- end card -->
            </div>
            <div class="mt-5 text-center">
              <p>
                Powered by 
                <span>
                  <a href="https://www.azerion.com/" target="_blank">Azerion</a>
                </span>
                
                <!-- © {{ new Date().getFullYear() }} Minible. Crafted with
                <i class="mdi mdi-heart text-danger"></i> by Themesbrand -->
              </p>
            </div>
            <!-- end row -->
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
    </div>
  </div>
</template>

<style lang="scss" module></style>
